import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PuffLoader } from "react-spinners";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { FaThumbsUp } from "react-icons/fa";

const VideoDetail = () => {
  const { id } = useParams();
  const [video, setVideo] = useState(null);
  const [relatedVideos, setRelatedVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [likes, setLikes] = useState(0); // State untuk jumlah like
  const [liked, setLiked] = useState(false); // State untuk status like
  const [error, setError] = useState(false);
  const timeSinceUpload = video?.uploaded_at
    ? formatDistanceToNow(parseISO(video.uploaded_at)) + ' ago'
    : 'Unknown time';

  useEffect(() => {
    // Fetch video details
    fetch(`https://just-share.info/stream/video/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setVideo(data.video);
        setLikes(data.video.likes || 0); // Set jumlah likes dari server
        setLiked(data.video.liked_by_user || false); // Apakah user sudah like
        
        if (data.video.categories) {
          setCategories(data.video.categories); // Set category directly from video data
        }

        // Fetch related videos based on tags
        if (data.video.tags?.length > 0) {
          const tagIds = data.video.tags.map(tag => tag.id); // Get the tag IDs

          // Fetch related videos based on the tag IDs
          fetch(`https://just-share.info/stream/videos/tags/` + tagIds.join(","))
            .then((response) => response.json())
            .then((relatedData) => {
              setRelatedVideos(relatedData.results || []);
            })
            .catch((error) => console.error("Error fetching related videos:", error));
        }

        // Fetch related videos if category.slug exists
        if (data.video.category?.slug) {
          fetch(`https://just-share.info/stream/videos/category/${data.video.category.slug}/`)
            .then((response) => response.json())
            .then((relatedData) => {
              setRelatedVideos(relatedData.results || []);
            })
            .catch((error) => console.error("Error fetching related videos:", error));
        }
      })
      .catch((error) => console.error("Error fetching video details:", error));

    // Fetch categories
    fetch(`https://just-share.info/stream/videos/categories/`)
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, [id]);

const handleLike = async () => {
  try {
    const response = await fetch(`https://just-share.info/stream/video/${id}/like/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      // Perbarui status liked dan jumlah likes
      setLiked(true); // Menandakan bahwa video sudah di-like
      setLikes(data.likes); // Perbarui jumlah likes dengan nilai terbaru
    } else {
      console.error('Failed to like the video');
    }
  } catch (error) {
    console.error('Error liking the video:', error);
  }
};



  if (!video) {
    return (
      <div className="flex items-center justify-center h-screen">
        <PuffLoader color="#4A90E2" size={60} />
        <p className="text-gray-500 ml-4">Loading...</p>
      </div>
    );
  }

  return (
    <>
      {/* SEO Section */}
      <Helmet>
        <title>{video.title || "Video Details"}</title>
        <meta name="description" content={video.description?.slice(0, 150) || "Deskripsi video tidak tersedia"} />
        <meta name="keywords" content={video.tags?.length > 0 ? video.tags.join(", ") : "video, streaming, category"} />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="video.other" />
        <meta property="og:title" content={video.title || "Amazing Video!"} />
        <meta property="og:description" content={video.description || "Top Anime Clips, Straight to the Point!"} />
        <meta property="og:image" content={video.thumbnailUrl || "https://videos.just-share.info/favicon.ico"} />
        <meta property="og:video" content={video.video_file ? `https://just-share.info${video.video_file}` : "https://example.com/videos/amazing-video.mp4"} />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />
        <meta property="og:url" content={window.location.href || "https://example.com/video/123"} />
      </Helmet>

      <div className="max-w-7xl mx-auto p-4">
        {/* Header Section */}


        {/* Main Content */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Video Player */}
          <div className="flex-1 bg-white p-6 rounded-lg shadow-lg">
            <div className="relative aspect-w-16 aspect-h-9">
              <video
                key={video.id}
                className="w-full h-full object-cover rounded-md shadow-md"
                controls
              >
                <source
                  src={`https://just-share.info${video.video_file || "/fallback.mp4"}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>

            {/* Title and Views */}
            <div className="mt-4">
              <h1 className="text-2xl font-semibold text-gray-900">{video.title || "Untitled Video"}</h1>
              <p className="text-sm text-gray-500 mt-2">
                {video.views || 0} views | {timeSinceUpload}
              </p>
            </div>
      <div className="mt-4 flex items-center gap-4">
        {/* Tombol Like */}
        <button
          onClick={handleLike}
          className={`flex items-center px-4 py-2 rounded-md ${liked ? "bg-blue-600 text-white" : "bg-gray-300 text-gray-800"} transition`}
        >
          <FaThumbsUp className={`mr-2 ${liked ? "text-blue-600" : "text-gray-500"}`} />
          {liked ? "Liked" : "Like"}
        </button>
        
        {/* Jumlah Like */}
        <p className="text-lg text-gray-800">{likes} Likes</p>
      </div>

            {/* Description */}
            <div className="mt-4">
              <p className="text-gray-700 leading-relaxed text-lg">
                {video.description || "No description available"}
              </p>
            </div>

            {/* Category */}
            {video.category && (
              <div className="mt-4">
                <p className="text-sm text-gray-500">Category:</p>
                <Link
                  to={`/videos/category/${video.category.slug}`}
                  className="text-lg font-medium text-blue-600 hover:underline"
                >
                  {video.category.name}
                </Link>
              </div>
            )}

            {/* Tags */}
            {Array.isArray(video.tags) && video.tags.length > 0 && (
              <div className="mt-4">
                <p className="text-sm text-gray-500">Tags:</p>
                <div className="flex flex-wrap gap-2">
                  {video.tags.map((tag) => (
                    <Link
                      key={tag.id}
                      to={`/videos/tag/${encodeURIComponent(tag.name)}`}
                      className="px-3 py-1 bg-blue-100 text-blue-600 text-sm font-medium rounded hover:bg-blue-200 transition"
                    >
                      {tag.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}
<h3 className="text-lg font-semibold text-gray-800 mb-2">Share this videos:</h3>
<div className="flex items-center gap-4">
  {/* Facebook */}
  <a
    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`}
    target="_blank"
    rel="noopener noreferrer"
    className="hover:opacity-80"
    aria-label="Share on Facebook"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#4267B2"
      viewBox="0 0 24 24"
    >
      <path d="M24 12.073C24 5.405 18.627 0 12 0S0 5.405 0 12.073c0 5.988 4.388 10.951 10.125 11.854V15.48h-3.047v-3.408h3.047v-2.605c0-3.015 1.793-4.686 4.533-4.686 1.313 0 2.686.235 2.686.235v2.965h-1.514c-1.491 0-1.955.93-1.955 1.882v2.209h3.328l-.532 3.408h-2.796v8.447C19.612 23.024 24 18.061 24 12.073z" />
    </svg>
  </a>

  {/* Twitter */}
  <a
    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(video.title || "Check out this video!")}`}
    target="_blank"
    rel="noopener noreferrer"
    className="hover:opacity-80"
    aria-label="Share on Twitter"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#1DA1F2"
      viewBox="0 0 24 24"
    >
      <path d="M24 4.557a9.828 9.828 0 0 1-2.828.775 4.93 4.93 0 0 0 2.165-2.723 9.869 9.869 0 0 1-3.127 1.194A4.916 4.916 0 0 0 16.616 3c-2.72 0-4.92 2.197-4.92 4.917 0 .386.045.762.127 1.125-4.086-.205-7.713-2.164-10.14-5.144a4.914 4.914 0 0 0-.666 2.472c0 1.704.869 3.206 2.19 4.086a4.904 4.904 0 0 1-2.229-.616v.061c0 2.384 1.697 4.374 3.946 4.827a4.935 4.935 0 0 1-2.224.086c.629 1.957 2.448 3.382 4.604 3.424A9.865 9.865 0 0 1 0 19.54a13.892 13.892 0 0 0 7.548 2.21c9.054 0 14.001-7.496 14.001-13.986 0-.21-.005-.422-.015-.632A9.935 9.935 0 0 0 24 4.557z" />
    </svg>
  </a>

  {/* WhatsApp */}
  <a
    href={`https://wa.me/?text=${encodeURIComponent(video.title || "Check out this video!")}%20${encodeURIComponent(window.location.href)}`}
    target="_blank"
    rel="noopener noreferrer"
    className="hover:opacity-80"
    aria-label="Share on WhatsApp"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#25D366"
      viewBox="0 0 24 24"
    >
      <path d="M12 0a12 12 0 0 0-12 12c0 2.11.554 4.09 1.515 5.815L0 24l6.365-1.503a12 12 0 0 0 5.635 1.503 12 12 0 0 0 12-12c0-6.627-5.373-12-12-12zm5.79 17.173-.693.656c-.106.099-2.573 2.461-7.158-.03-3.806-2.015-5.664-5.654-6.089-6.356l-.517-.863c-.121-.202-.65-1.146-.628-2.191.022-1.046.688-1.587.93-1.797.243-.209 1.294-.861 1.592-.957.3-.096.592-.072.826.038.234.111.368.548.421.78.053.233.053.427.038.588-.016.161-.108.399-.161.532-.053.134-.096.202-.19.316-.094.115-.2.241-.292.337-.083.086-.198.198-.298.313-.101.116-.208.198-.228.253-.021.054-.147.152-.053.3.094.146.516.848 1.137 1.534.801.883 1.779 1.429 2.024 1.555.246.126.392.116.53-.048.138-.162.636-.714.808-.955.171-.243.339-.226.558-.136.219.091 1.392.657 1.615.776.224.12.371.178.426.278.054.1.054.578-.14 1.135z" />
    </svg>
  </a>

  {/* Copy Link */}
  <button
    onClick={() => navigator.clipboard.writeText(window.location.href)}
    className="hover:opacity-80"
    aria-label="Copy Link"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="#6B7280"
      viewBox="0 0 24 24"
    >
      <path d="M16 1a5 5 0 0 1 5 5v6h-2V6a3 3 0 0 0-3-3H6a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h6v2H6a5 5 0 0 1-5-5V6a5 5 0 0 1 5-5h10zm5 10a5 5 0 0 1 5 5v6a5 5 0 0 1-5 5H11a5 5 0 0 1-5-5v-6a5 5 0 0 1 5-5h10zm-3 3H11a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3z" />
    </svg>
  </button>
</div>

          </div>

          {/* Sidebar with Related Videos */}
          <aside className="w-full lg:w-1/3">
            <h2 className="text-xl font-semibold mb-4">Related Videos</h2>
            <ul className="mt-4">
              {relatedVideos.slice(0, 5).map((relatedVideo) => (
                <li key={relatedVideo.id} className="flex items-center space-x-4 mb-4">
                  <video className="w-24 h-16 object-cover rounded-md" controls>
                    <source
                      src={`https://just-share.info${relatedVideo.video_file || "/fallback.mp4"}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <div>
                    <Link
                      to={`/video/${relatedVideo.id}`}
                      className="text-blue-500 hover:underline"
                    >
                      {relatedVideo.title || "Untitled Video"}
                    </Link>
                    <p className="text-sm text-gray-500">
                      {relatedVideo.description?.slice(0, 50) || "No description available"}...
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </aside>
        </div>
      </div>
    </>
  );
};

export default VideoDetail;